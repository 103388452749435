import { faChevronRight, faCupTogo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { getLocationSlug } from '../api-utils'
import { location as locationRequests } from '../api-utils/service-requests/location'
import { withAppPageProps } from '../api-utils/service-requests/utils'
import { Locale, makeLocale } from '../components/helpers'
import { Hero } from '../components/hero'
import { SessionlessPage } from '../components/page-wrappers'
import { Layout } from '../components/page-wrappers/layout'
import { GetSessionlessPageProps } from '../components/page-wrappers/types'
import { useUserProfile } from '../hooks/useUserProfile'
import { GetLocationInfoResponse } from '../models/types'
import { Heading } from '../ui/Typography'

type Props = {
  information: GetLocationInfoResponse
}

const QrCodePage = ({ information }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]
  const { takeawayEnabled } = information
  const userProfile = useUserProfile()

  return (
    <SessionlessPage information={information} userProfile={userProfile}>
      <Layout
        title={translation.scanToOrder}
        information={information}
        navBarOptions={{ leftButtons: [], transparentBackground: true }}>
        <Hero
          locationName={information.name}
          featureImage={information.featureImageUrl || ''}
          logoImage={information.logoImageUrl || ''}
        />
        <div className="container flex flex-col gap-y-4 pt-8 px-8">
          <Heading level={2}>{translation.scanToOrder}</Heading>
          <div>{translation.scanToOrderDescription}</div>
          {takeawayEnabled && (
            <Link className="flex flex-row items-center gap-x-3" href="/takeaway">
              <div className="flex justify-center items-center rounded-full w-12 h-12 bg-inline-info text-default">
                <FontAwesomeIcon size="lg" icon={faCupTogo} />
              </div>
              <div className="flex items-center">
                {translation.orOrderTakeaway}
                <FontAwesomeIcon className="relative top-[1px] ml-2" icon={faChevronRight} />
              </div>
            </Link>
          )}
        </div>
      </Layout>
    </SessionlessPage>
  )
}

export default QrCodePage

const getProps: GetSessionlessPageProps<Props> = async (context) => {
  const slug = getLocationSlug(context)
  const information = await locationRequests.information(slug, {}, context)
  return {
    props: {
      information,
    },
  }
}
export const getServerSideProps = withAppPageProps(getProps)

const translations: {
  [key in Locale]: {
    scanToOrder: string
    scanToOrderDescription: string
    orOrderTakeaway: string
  }
} = {
  sv: {
    scanToOrder: 'Skanna och beställ',
    scanToOrderDescription: 'Skanna QR-koden på ditt bord för att beställa',
    orOrderTakeaway: 'Eller beställ takeaway',
  },
  en: {
    scanToOrder: 'Scan to order',
    scanToOrderDescription: 'Scan the QR code at your table to order',
    orOrderTakeaway: 'Or order takeaway',
  },
  no: {
    scanToOrder: 'Skann og bestill',
    scanToOrderDescription: 'Skann QR-koden på bordet ditt for å bestille',
    orOrderTakeaway: 'Eller bestill takeaway',
  },
}
