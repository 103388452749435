import Link from 'next/link'
import React from 'react'

import { LazyImage } from '../lazy-image'

type Props = {
  locationName: string
  featureImage: string
  logoImage: string
}

export const Hero = ({ locationName, featureImage, logoImage }: Props) => {
  return (
    <div className="w-full">
      <Link href={`/menu/`}>
        <div className="relative h-52 sm:h-72 md:h-60">
          {/* Background image container */}
          <div className="absolute inset-0 sm:rounded-lg overflow-hidden">
            {featureImage ? (
              <LazyImage src={featureImage} alt={''} />
            ) : (
              <div className="h-full w-full object-cover" />
            )}
          </div>

          {/* Content container */}
          <div className="relative h-full flex justify-center items-center">
            {logoImage ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                className="absolute -bottom-6 max-h-[120px] max-w-[240px]"
                src={logoImage}
                alt={''}
              />
            ) : (
              <div className="text-4xl text-lg-bold text-center text-white max-w-[240px]">
                {locationName}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}
