import { useContext } from 'react'

import { UserProfileContext } from './context'

/** Provides access to the global userProfile. Requires that the page's getServersideProps is wrapped with withAppPageProps() */
export const useUserProfile = () => {
  const context = useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error('useUserProfile() must be used within a <UserProfileProvider />')
  }
  return context
}
